import React from "react";

export default function ProjectInfo({ details }) {
    const displayStyle = {
        display: details.isDisplayed ? "block" : "none",
    };

    return (
        <div
            className="card"
            style={displayStyle}
            data-level={details.level}
            data-name={details.title}
        >
            <div className="card__cover">
                <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href={details.demo_link}
                >
                    <img
                        src={details.img}
                        alt={details.title}
                        className="card__img"
                    />
                </a>
            </div>
            <div className="card__text zoom" style={{backgroundColor: "black"}}>
                <h2 className="card__title">{details.title}</h2><br />
                <p className="card__subtitle">{details.subtitle}</p>
                <p className="card__description">{details.description}</p><br />
                <p className="card__level">Stack: {details.tech.join(", ")}</p><br/>
                <p className="card__level">Tags:{details.type.join(" - ")}</p>
                <div className="card__links">
                    <a
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{ marginRight: "10px" }}
                        href={details.demo_link}
                        className="card__link"
                    >
                                                <p className='keystudios'>View in App Store</p>

                        <img src="https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png" alt="View on App Store" width={25}></img>
                    </a>

                </div>
            </div>
        </div>
    );
}
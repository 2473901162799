import Content from './component/pages/Content'
import About from "./component/pages/About Key"; 
import Projects from './component/pages/Projects';
import Support from './component/pages/Support';
import Navbar from "./component/utilities/Navbar"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import GetStarted from './component/pages/Get Started';
import Privacy from './component/pages/Privacy';
import Footer from './component/utilities/Footer';

function App() {

    
  return (
    
        

<Router>
<Navbar/>
                <Routes>
                    {/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
                    <Route
                        exact
                        path="/"
                        element={<Content />}
                    />
 
                    {/* This route is for about component 
          with exact path "/about", in component 
          props we passes the imported component*/}
                    <Route
                        path="/about"
                        element={<About />}
                    />

<Route
                        path="/projects"
                        element={<Projects />}
                    />

<Route
                        path="/support"
                        element={<Support />}
                    />
                    
                    <Route
                    path="/get-started"
                    element={<GetStarted />}
                />

<Route
                    path="/privacy"
                    element={<Privacy />}
                />
 
                    {/* This route is for contactus component
          with exact path "/contactus", in 
          component props we passes the imported component}
                    <Route
                        path="/contactus"
                        element={<Contact />}
                    />
 
                    {/* If any route mismatches the upper 
          route endpoints then, redirect triggers 
          and redirects app to home component with to="/" */}
                    {/* <Redirect to="/" /> */}
                    {/* <Route
                        path="*"
                        element={<Navigate to="/" />}
                    /> */}
                    
                </Routes>
                <Footer/>

            </Router>

  );
}

export default App;

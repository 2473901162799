// Filename - components/Footer.js

import React from "react";
import {
	Box,
	FooterContainer,
	Row,
	Column,
	FooterLink,
	Heading,
} from "../css/FooterStyles";

const Footer = () => {
	return (
		<Box >
			<h1
				style={{
					color: "white",
					textAlign: "center",
					marginBottom: "3rem",
                    fontSize: "2rem"
				}}
			>
				The place where digital media begins!
			</h1>
			<FooterContainer >
				<Row>
					<Column>
						<Heading>About Us</Heading>
						<FooterLink href="/about">
							Meet Key
						</FooterLink>

						<FooterLink href="/privacy">
							Privacy
						</FooterLink>
					</Column>

					<Column className=" professions">
                   

						<Heading>Offered Services</Heading>
						<p href="#">
							App & Web Development
						</p>

						<p href="#">
							Cinematography & Photography
						</p>

						<p href="#">
							Music Production
						</p>                        

                        <p href="#">
							Home & Car Audio Installation
						</p>

                        <p href="#">
							Device Repair
						</p>

                        <p href="#">
							Data Management/Recovery
						</p>

					</Column>
					<Column>
						<Heading>Contact Us</Heading>


						<FooterLink href="/get-started">
							Collaborate
						</FooterLink>
						


						<FooterLink href="/support">
							Support Ticket
						</FooterLink>
						<p href="#">
							Email us: support@keysmediagroup.com
						</p>
					</Column>
					
				</Row>
			</FooterContainer>

            <h1 className="keystudios center">Key's Media Group Ltd. ©</h1>
		</Box>
	);
};
export default Footer;

import Showcase from './Showcase';
import SimpleCarousel from "./Carousel";
import React from 'react';
import useWindowDimensions from './WindowDimensions';

export default function Gallery({ isDark, data}) {
    const {width} = useWindowDimensions();

    const theme = {
        backgroundColor: isDark ? "black" : "white",
        transition: "500ms",
        color: isDark ? "white" : "black",
    };

    return (
        
        <section className="" id="projects" style={theme}>

            <div className="gallery" >


                {/* <Search handleSearch={handleSearch} handleSort={handleSort} /> */}

                {width < 1150 ?
                <SimpleCarousel challenges={data}/>
            
                :

                <Showcase challenges={data} theme={theme} />
                }
                           </div>

        </section>
    );
}

import wave from '../../img/wave.png'
import { Link } from 'react-router-dom';
function About() {
  return (
    

<main>

<div className="header"></div>

<div className='bg-text form'>


<div className='aboutHeader'>

    <h1 className='intro'>Hi <img src={wave} alt="wave" width={40} /></h1>
    <h1 style={{display: "flex", flexDirection: "row"}} className='intro'>I'm Key.</h1>
    <h1 className='intro'>Full Stack Developer</h1><br />

    
</div>
    <p className='about'>
      I've loved technology since for as long as I can remember. 
      Never able to choose a favorite between Engineering (App Development) and Content Creation; I chose both.  I love digital media. <br/>
      <br />
      Now a professional: 

      </p>
    <ul className='about professions'>
    <h3>
      - Software Developer.<br />
      - Digital Media Creator.<br/>
      - Director of Photography. <br />
      - Producer. <br /><br/> 
      </h3>
      </ul>
     <br/>   
    <div className='buttons'>
    <Link className='cta hire zoom' to='/projects'>SEE MY PROJECTS</Link>

    <Link className="cta hire zoom" to='/get-started'>LET'S WORK!</Link>

  </div>
    </div>

</main>

  )
}

export default About;
import React from 'react'
import Header from '../utilities/Header';
import ContactForm from '../utilities/Contact Form';
function Support() {
  return (

<main>    

  <Header/>
  <div className='bg-text form'>
    <h1>Support<br/></h1>        
    <ContactForm formType='support'/>

    </div> 

</main>
  )
}

export default Support
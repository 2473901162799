import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../utilities/Header';
import { Stack } from '@mui/system';
function Content() {
  return (

<main>    

  <Header/>
  <div className='bg-text'>
    <h1>For all your digital needs.<br/></h1>        
    <Stack className='center'
direction="row"
spacing={1}
>
<h1>Choose</h1>
<h1 className='kmg'>KMG</h1>

</Stack>

<div className='actionButtonStack'>

<Link className="nav__cta quote zoom" to='/get-started'>Get Started</Link>


<Link className="support hire zoom" to='/support'>Support</Link>

</div>


</div>



<div className='rebranding'>  
<p className='smallRebrand'>We're rebranding!<br /></p>
<p className='keystudios'>Formerly - Key Studios<br /></p>

</div> 


</main>
  )
}

export default Content
import { useState } from 'react';
import ReactSimplyCarousel from 'react-simply-carousel';
import Challenge from './ProjectInfo';
import left from "../../img/arrow-left.svg"
import right from "../../img/arrow-right.svg"
function SimpleCarousel({challenges}) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <div  className='bg-text'
    >
      <ReactSimplyCarousel
        activeSlideIndex={activeSlideIndex}
        onRequestChange={setActiveSlideIndex}
        itemsToShow={1}
        itemsToScroll={1}
        
        forwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          className: "zoom",
          style: {
            alignSelf: 'center',
            alignContent: 'center',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            color: 'black',
            cursor: 'pointer',
            height: 40,
            width: 40,
            
          },
          children: <img src={right} alt="Next Project" width={20} />,
        }}
        backwardBtnProps={{
          //here you can also pass className, or any other button element attributes
          className: "zoom",
          style: {
            alignSelf: 'center',
            alignContent: 'center',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            color: 'black',
            cursor: 'pointer',
            height: 40,
            width: 40,
          },
          children: <img src={left} alt="Previous Project" width={20} />,
        }}
        responsiveProps={[
          {
            itemsToShow: 1,
            itemsToScroll: 1,
            display: "flex",
            flexDirection: "row",
          },
        ]}
        speed={400}
        easing="linear"
      >
        {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}
        {challenges && challenges.map((item) => {
                return (


                    <div>


                            <Challenge
                            key={item.title}
                            details={item}
                        />
                        



                    </div>

                );
            })}

      </ReactSimplyCarousel>
    </div>
  );
}

export default SimpleCarousel;
import {React,useState, useEffect} from "react";
import Gallery from "../utilities/Gallery";
import {ReactComponent as Logo} from "../../img/play.svg"

var showLatest = false;
export default function Projects() {
    const latestProjVid = "https://apptrailers.itunes.apple.com/itunes-assets/PurpleVideo126/v4/ef/24/75/ef24750f-677f-1a00-c565-f9a59a0b9dee/P587212403_default.m3u8";
    const [showLatestTrailer, setLatest] = useState(showLatest);

    const [challenges, setChallenges] = useState([]);
   


    useEffect(() => {
        fetch("data.json")
            .then((res) => res.json())
            .then((data) => {
                setChallenges(data.data);
            });
    }, []);

    const handleChange = (text) => {
        setChallenges(
            challenges.map((item) => {
                if (text === "") {
                    return { ...item, isDisplayed: true };
                } else if (item.title.toLowerCase().includes(text)) {
                    return { ...item, isDisplayed: true };
                } else {
                    return { ...item, isDisplayed: false };
                }
            })
        );
    };

    const handleSort = (level) => {
        setChallenges(
            challenges.map((item) => {
                if (item.level === level) {
                    return { ...item, isDisplayed: true };
                } else if (level === "all") {
                    return { ...item, isDisplayed: true };
                } else {
                    return { ...item, isDisplayed: false };
                }
            })
        );
    };

//console.log(fetchRes())
    return (

<div className="">
<div className="header"></div>
            <div className="bg-text">

            <h2 className="gallery__title">Projects</h2>
            {
           
           !showLatestTrailer ? 
           
           <button className='latestProj' onClick
            ={() => {
          setLatest(!showLatestTrailer);
        }}>
            <div className="">

            <p className="">Latest Game Trailer</p>
            <Logo className='zoom' fill='white' width={30} height={30}/> 
            
            </div>
            
            </button>

        :


        <button className='latestProj' onClick
        ={() => {
      setLatest(!showLatestTrailer);
    
    }}>Close [x]</button>

    
}

{showLatestTrailer ?
<div className="latestProjectVideo">
    <video className="latestProjVideo" width={80} autoPlay controls >
          <source src={latestProjVid} type="application/x-mpegURL"/>
    </video>

   
    </div>
    :

<div>


<Gallery
data={challenges}
handleSearch={handleChange}
handleSort={handleSort}
/>



</div>

}
            </div>

    </div>



    )
}



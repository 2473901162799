import privacy from "../../img/privacy.jpeg" 
function Privacy() {
  return (
    

<main>

<div className="header"></div>


<img className="privacy" src={privacy} alt="Privacy Document" width="100" />


</main>

  )
}

export default Privacy;
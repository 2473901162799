import React, { useState } from "react";

const KMG_SUPPORT_FORM_ENDPOINT = "https://public.herotofu.com/v1/c590d6e0-163b-11ef-bc5a-29d59b483c7e"; 
const KMG_QUOTE_FORM_ENDPOINT = "https://public.herotofu.com/v1/9933ecd0-164b-11ef-a449-3b7e66789316"; 

export default function ContactForm({formType="support"}) {
    const form = formType;
  const [status] = useState();


  if (status) {
    return (
      <>
        <div style={{margin: 20}} className="text-2xl">Thank you!<br />.</div>
        <div className="text-md">{status}</div>
      </>
    );
  }

  return (
<form action={form === "support" ? KMG_SUPPORT_FORM_ENDPOINT : KMG_QUOTE_FORM_ENDPOINT} method="post" accept-charset="UTF-8">
            
      {
      form === "support" ? 
      
      <p className='about'>
            <br/> If you need support with a KMG application please submit the form below.  <br/>
      </p> 
      
      : 
      
      <p className='about'>For business inquiries please submit the form below.<br/> We're looking forward to doing business.<br/>
      </p> 
      }
    

     <div >
        <input type="text" placeholder="Your name" name="Name" required />
      </div>
     <div>
     <input type="email" placeholder="Email" name="Email" required />
     </div>

{ form === "support" ?      

<input type="text" placeholder="Which application?" name="Application" required /> 

:
<div >
<p style={{textAlign: "left"}}></p>Which service are you seeking?<br/><p/>
    <p className="keystudios" style={{textAlign: "left"}}>App Development, Cinematography, Photography, Website Design, Music Production, Audio Installation, Data Management, Repair</p>

<input type="text" placeholder="Service?" name="Application" required />

</div>
    }

{ form === "support" ?    

<div style={{margin: 10}}>

Describe the issue you are having.<br/>
<p className="keystudios" style={{textAlign: "left"}}>While not required - we'd appreciate steps to reproduce the issue.</p>

</div>
    
    :
    <div style={{margin: 10}}>

Describe the goal for the project.<br/>
<p className="keystudios" style={{textAlign: "left"}}>Are there any immediate concerns/obstacles for completion?</p>

</div>
}

        <textarea className="focus:outline-none focus:ring relative w-full px-3 py-3 text-sm text-gray-600 placeholder-gray-400 bg-white border-0 rounded shadow outline-none"
            placeholder="Your message" name="Message" required />
            Please be as specific as possible.
      
      <div>

        <button className="cta submit zoom" type="submit"> Submit </button>
        { form === "support" ?    
        
        <div>
        <p className="keystudios">Be sure to specify the application you need support for.</p>

        <p className="keystudios">Please allow ~2-3 business days for a response.</p>
</div>
        :
        <p className="keystudios">Please allow  ~1 business days for a response.</p>

}
        <p className="keystudios">support@keysmediagroup.com</p>

      </div>
      <div style={{display:"none"}}>
      <input type="text" name="_gotcha" tabindex="-1" autocomplete="off" />
    </div>

    </form>
  );
};


import {React} from "react";
import Challenge from "./ProjectInfo";

export default function Showcase({ challenges, theme }) {  

    return (
        <div className="container">
            {challenges && challenges.map((item) => {
                return (


                    <div>

                        {

                            item.title === "Color Drop!" ?
                            <div                             className="highlightedProject"
                            >

                            <Challenge
                            key={item.title}
                            details={item}
                            theme={theme}
                            />
                            </div>
                       
                            :

                            <Challenge
                            key={item.title}
                            details={item}
                            theme={theme}
                        />
                        }
                    </div>

                );
            })}
        </div>
    );
}